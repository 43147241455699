import React from 'react'
import { Link } from 'gatsby'
import styled from "@emotion/styled"

import Logo from './logo'

const Fpsctv = styled.div`
position: fixed;
z-index: 3;
top: 1.75vh;
left: 50%;
transform: translateX(-50%);
font-family: 'Faune';
font-size: 1.5rem;
a {
  color: rgb(0,0,0);
}
`

const Header = () => {
    return (
        <header>
            <Logo />
            <Fpsctv><Link to="/">Futurespective</Link></Fpsctv>
        </header>
    )
}

export default Header;