import React from 'react'
import { Link } from 'gatsby'
import styled from "@emotion/styled"

const Logowrap = styled.div`
position: fixed;
z-index: 11;
right: 2vw;
top: 1.5vw;
font-family: courier;
font-weight: bold;
font-size: .875rem;
line-height: 1em;
a {
    color: rgb(0,0,0);
    padding-bottom: 3px;
    text-decoration: none;
    border-bottom: solid 2px rgb(0,0,0);
    transition: border-bottom-color 0.3s ease;
    &:hover {
        border-bottom-color: rgb(255,225,0);
    }
}
`

export default function Logo() {
    return (
        <Logowrap><Link to="/">designinquiry</Link></Logowrap>
    )
}