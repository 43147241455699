import React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { ArrowRight} from "phosphor-react";
import "@fontsource/public-sans/400.css"
import "@fontsource/public-sans/700.css"

import Header from '../components/header'

const Image = styled.div`
width: 30vw;
height: auto;
position: fixed;
top: 50%;
left: 50%;
z-index: 2;
transform: translate(-50%, -50%);
img {
  animation: rotating 90s linear infinite;
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
`
const CarouselWrap = styled.div`
  height: 100vh;
  position: relative;
  .carousel.carousel-slider {
      margin: 0 auto;
  }
  .carousel.carousel-slider .control-arrow:hover {
    background: transparent;
  }
  .carousel .slider {
    height: 100vh;
  }
  .carousel .slide > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  button {
    background: rgba(255,255,255,0.4);
    border: 0;
    padding: 0;
    color: rgba(0,0,0,.2);
    position: absolute;
    z-index: 5;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    &:hover {
        cursor: pointer;
        color: rgba(0,0,0,1);
        background: rgba(255,255,255,.8);
    }
}
`
const Homenav = styled.nav`
width: 25vw;
overflow: hidden;
position: fixed;
right: 0;
top: 50%;
transform: translateY(-50%);
z-index: 3;
font-family: "Public Sans";
padding: 2.5vh 0 2.5vh 2.5vh;
li {
  padding: 1.5vh 0;
}
a {
  color: #000;
  font-size: .9125rem;
  letter-spacing: .25px;
  font-weight: 700;
  transition: all 0.3s ease;
  text-decoration: none;
  text-transform: uppercase;
  background-color: rgba(255,255,255,0.5);
  padding: .5vw 1.25vw .5vw 1vw;
  &:hover {
    background-color: #000;
    color: #fff;
  }
  svg {
    display: inline-table;
    vertical-align: top;
  }
}
`

const IndexPage = () => {
  return (
    <>
    <Header />
    <Image><StaticImage src="../images/fspctv-logo.png" alt="DI Futurespective" placeholder="tracedSVG" /></Image>
    <Homenav>
        <ul>
          <li><a href=""><ArrowRight size={18} weight="bold" /> What is Futurespective?</a></li>
          <li><a href=""><ArrowRight size={18} weight="bold" /> Generosity of Airspace</a></li>
          <li><Link to="/aliveness"><ArrowRight size={18} weight="bold" /> Aliveness</Link></li>
          <li><Link to="/press"><ArrowRight size={18} weight="bold" /> Press</Link></li>
          <li><a href=""><ArrowRight size={18} weight="bold" /> Futurespective Publications</a></li>
          <li><a href=""><ArrowRight size={18} weight="bold" /> Everything is a Project</a></li>
          <li><Link to="/we-do-our-best-work-together"><ArrowRight size={18} weight="bold" /> We Do Our Best Work Together</Link></li>
        </ul>
    </Homenav>
    <main>
      <CarouselWrap>
      <Carousel animationHandler="fade" width={'100vw'} showThumbs={false} showStatus={false} showIndicators={false} autoPlay={true} infiniteLoop={true} swipeable={false} interval={10000} stopOnHover={false}>
        <div><StaticImage src="../images/home/DSC00703 copy.JPG" alt="DI Futurespective 1" layout='fullWidth' /></div>
        <div><StaticImage src="../images/home/IMG_1950.jpg" alt="DI Futurespective 2" layout='fullWidth' /></div>
        <div><StaticImage src="../images/home/seriously_grand_03.jpg" alt="DI Futurespective 3" layout='fullWidth' /></div>
        <div><StaticImage src="../images/home/seriously_grand_17.JPG" alt="DI Futurespective 5" layout='fullWidth' /></div>
        <div><StaticImage src="../images/home/seriously_grand_24.JPG" alt="DI Futurespective 6" layout='fullWidth' /></div>
        <div><StaticImage src="../images/home/seriously_grand_27.jpg" alt="DI Futurespective 7" layout='fullWidth' /></div>
        <div><StaticImage src="../images/home/seriously_grand_35.jpg" alt="DI Futurespective 8" layout='fullWidth' /></div>
        <div><StaticImage src="../images/home/seriously_grand_39.jpg" alt="DI Futurespective 8" layout='fullWidth' /></div>
     </Carousel>
      </CarouselWrap>
    </main>
    </>
  )
}

export default IndexPage
